@value text-color: #08458e;
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');

.container {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.infoHeader {
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: text-color;
  text-align: center;
}

.background {
  background-image: url('../../images/banner5.jpg');
  height: 3500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.overflowCard {
  margin: 10% 0;
}

.overFlowTitle {
  color: #08458e;
  text-align: center;
  font-size: 3rem;
  font-family: 'playfair', sans-serif;
}

.conditionsCard {
  margin: 10% 0 5% 0;
}

.map{
  width: 540px;
  height: 800px;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.parkingCard {
  margin-bottom: 10%;
}

.parkingTitle {
  text-align: center;
  color: text-color;
}

.parkingText {
  color: text-color;
}

.parkingImg {
  width: 100%;
  height: auto;
}

.weatherTitle {
  text-align: center;
  color: text-color;
}

.spacer {
  width: 90%;
  border-top: 2px solid #08458e;
}

.icons {
  width: 40px;
  height: 40px;
  float: left;
}

.bottomWebCam {
  width: 40px;
  height: 40px;
  float: left;
  padding-top: 3px;
}

.iconText {
  margin-top: 4%;
  color: text-color;
}

.weatherAPITitle {
  text-align: center;
  color: #fff;
  margin-top: 10%;
}

@media only screen and (max-width: 810px) {
  .map {
    margin-top: 60%;
  }

  .iconText {
    font-size: 1.1rem;
  }

  .icons {
    width: 30px;
    height: 30px;
  }

  .bottomWebCam{
    width: 30px;
    height: 30px;
  }

  .map {
    width: 100%;
    height: auto;
    margin-top: 5%;
  }

  .infoHeader {
    margin-top: 25%;
  }

  .background {
    height: 2800px;
  }
}

@media only screen and (max-width: 500px) {
  .map {
    width: 100%;
    height: auto;
  }

  .container {
    margin: 0 auto;
    width: 80%;
  }

  .parkingImg {
    margin-top: 10%;
  }

  .background {
    height: 3200px;
  }

  .icons {
    width: 30px;
    height: 30px;
  }

  .iconText {
    font-size: 1rem;
  }

  .bottomWebCam {
    width: 30px;
    height: 30px;
  }

  .infoHeader {
    margin-top: 50%;
  }

  .map {
    margin-top: 5%;
  }
}