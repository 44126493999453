@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');
@value text-color: #08458e;

.container {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.footer {
  background: #e6e6e6;
}

.footerList {
  margin-right: 100px;
}

.footer h4{
  margin-top: 5%;
  color: text-color;
  text-align: center;
}

.footer h2 {
  color: text-color;
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 3rem;
  text-align: center;
}


.footer h2:hover {
  cursor: pointer;
  text-decoration: none;
  opacity: 80%;
}

.footer p {
  color: text-color;
  text-align: center;
}

.footer ul{
  list-style: none;
  margin-top: 5%;
}

.footerLogo a{
  color: #06468c;
  font-size: 2.3rem;
}

.linkText {
  color: text-color;
  cursor: pointer;
}

.linkText:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;;
}

.copyright {
  background: #d9d9d9;
  height: 100px;
}

.xlLink {
  color: text-color;
}

@media only screen and (max-width: 810px) {
  .footer h2 {
    margin-top: -5px;
  }

  .footerList {
    margin-left: 0;
  }
  
  .linkText:hover {
    text-decoration: underline;
  }

  .footerList {
    margin-right: 0px;
  }

}

@media only screen and (max-width: 500px) {
  .footerList {
    margin-right: 35px !important;
  }

}