@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');
@value text-color: #08458e;

.background {
  background-image: url('../../images/banner3.jpg');
  height: 3450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.safetyHeader {
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: text-color;
  text-align: center;
}

.divider {
  background-color: #fff;;
}

.safetyNav ul{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background:  #08458e;
  width: 1100px;
  height: 60px;
 
}

.safetyList {
  list-style-type: initial;
}

.subList {
  list-style-type: initial;
}

.subList li {
  color: blue;
}

.safetyMenu ul li a {
  color: #fff;
}

.safetyMobileLinks li{
  display: none !important;
}

.cardHeader{
  color: #08458e;
  font-family: 'Dosis', sans-serif;
  font-size: 3rem;
}

.cardText {
  color: #08458e;
}

.safetyList li {
  color: #08458e;
}

.safetyMap:hover {
  cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in; 
  cursor: zoom-in;
}

.subHeader {
  font-weight: bold;
}

@media only screen and (max-width: 810px) {
  .safetyHeader {
    margin-top: 25%
  }

  .safetyNav ul{
    width: 615px;
  }

  .safetyMap {
    width: 100%;
  }

  .background {
    height: 3750px;
  }
}

@media only screen and (max-width: 700px) {
  .safetyNav {
    display: none;
  }

  .safetyHeader {
    margin-top: 45%;
    text-align: center;
  }
  
  .safetyMobileLinks li{
    display: block !important;
    text-align: center;
  }

  .background {
    height: 3900px;
  }

  .safetyMap {
    width: 100%;
    margin-top: 10%;
  }

  .cardHeader {
    font-size: 1rem;
  }

  .background {
    height: 3200px;
  }
}

@media only screen and (max-width: 500px) {
  .safetyMobileLinks a {
    color: #fff;
  }

  .background {
    height: 3600px !important;
  }
}