.rightAlign {
  justify-content: flex-end;
}

.container {
  max-width: 1100px;
  height: auto;
  margin: auto;
}

.nav {
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
}

.linkText {
  color: #08458e;
  float: right;
  margin-left: 10px;
}

/* Mobile styles */

@media only screen and (max-width: 810px) {
  .linkText {
    float: left;
  }
}

@media only screen and (max-width: 500px){
  .linkText {
    float: left;
  }

  .brand {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}