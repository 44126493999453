@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis:800&display=swap);
.Navstyles_rightAlign__2ipDB {
  justify-content: flex-end;
}

.Navstyles_container__3CnOG {
  max-width: 1100px;
  height: auto;
  margin: auto;
}

.Navstyles_nav__3KniU {
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  width: 100%;
}

.Navstyles_linkText__3gnoR {
  color: #08458e;
  float: right;
  margin-left: 10px;
}

/* Mobile styles */

@media only screen and (max-width: 810px) {
  .Navstyles_linkText__3gnoR {
    float: left;
  }
}

@media only screen and (max-width: 500px){
  .Navstyles_linkText__3gnoR {
    float: left;
  }

  .Navstyles_brand__2e8Kb {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
.Home_background__1VzIz {
  background-image: url(/static/media/home_banner.fcccde13.png);
  height: 750px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 25px;
}

.Home_smallScreen__2cJqy {
  display: none;
}

.Home_image__2g7nc {
  width: 90%;
  height: 500px;
}

.Home_bandImage__3cj5g {
  width: 100%;
  height: 300px;
}

.Home_coords__O2QeX {
  color: #08458e;
  margin-left: 0px;
}

.Home_compass__1WaYp {
  margin-left: 150px;
}

.Home_card__2A8-W {
  background: #3399ff !important;
  width: 400px;
  margin-left: 50px !important;
  margin-top: -50px;
}

.Home_card__2A8-W h3 {
  color: #fff;
  text-align: center;
}

.Home_card__2A8-W p {
  color: #fff;
}

.Home_flagCard__1saFd {
  margin-top: 20%;
}

.Home_marinaCard__2aYkr {
  margin-top: 20%;
}

.Home_eventsCard__3aYAX {
  margin-top: 10%;
}

.Home_button__1N2hc {
  width: 200px;
  height: 40px;
  padding: 7px;
  color: #fff;
  background: gray;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Home_button__1N2hc:hover {
  text-decoration: none;
}

.Home_parkPicsTitle__HgNaE {
  font-family: Playfair Display, serif;
  text-align: center;
  margin-top: 10%;
  color: #08458e;
}

.Home_parkPics__xwm8s {
  width: 350px;
  height: 350px;
}

.Home_compassContainer__1WtWS {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Mobile styles */
@media only screen and (min-width: 800px) and (max-width: 990px) {
  .Home_background__1VzIz {
    background-image: url(/static/media/home_banner_medium.bf71d93b.png);
  }

  .Home_smallScreen__2cJqy {
    display: block;
  }

  .Home_largeScreen__iXl7D {
    display: none;
  }

  .Home_coords__O2QeX {
    text-align: center;
  }

  .Home_compass__1WaYp {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Home_parkPics__xwm8s {
    width: 300px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Home_card__2A8-W {
    margin-left: 120px !important;
    margin-top: -50px !important;
    width: 500px;
    background: #fff !important;
  }

  .Home_card__2A8-W h3 {
    color: #3399ff;
  }

  .Home_card__2A8-W p{
    color: #3399ff;
  }

  .Home_flagCard__1saFd {
    margin-bottom: 5%;
    margin-top: 10% !important;
  }
  .Home_marinaCard__2aYkr {
    margin-bottom: 5%;
    margin-top: 10%;
  }

  .Home_eventsCard__3aYAX {
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .Home_section__1qsjp {
    background: #3399ff;
  }

  .Home_mobileSpacer__lSXnD {
    height: 2px !important;
	  color:  black;
  }

  .Home_image__2g7nc {
    margin-left: 120px;
    margin-bottom: 5%;
    width: 500px;
  }
}

@media only screen and (max-width: 500px) {  
  .Home_background__1VzIz {
    background-image: url(/static/media/home_banner_mobile3.2b441bb1.png);
  }

  .Home_coords__O2QeX {
    text-align: center;
  }

  .Home_flagCard__1saFd {
    margin-bottom: 5%;
    margin-top: 20% !important;
  }
  .Home_marinaCard__2aYkr {
    margin-bottom: 5%;
    margin-top: 15%;
  }

  .Home_eventsCard__3aYAX {
    margin-bottom: 5%;
    margin-top: 10% !important;
  }

  .Home_nav__2RJUg {
    position: relative;
  }

  .Home_smallScreen__2cJqy {
    display: block;
    margin-top: 10%;
  }

  .Home_largeScreen__iXl7D {
    display: none;
  }

  .Home_parkPics__xwm8s {
    width: 300px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Home_coords__O2QeX {
    text-align: center;
  }

  .Home_compass__1WaYp {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 150px;
    height: auto;
  }

  .Home_card__2A8-W {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    background: #fff !important;
    width: 350px;
  }

  .Home_image__2g7nc {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    height: auto;
  }

  .Home_golfCartImage__2cT4l {
    width: 100%;
  }

  .Home_overviewImage__2Mz5j {
    width: 100%;
    height: 300px;
  }

  .Home_card__2A8-W h3 {
    color: #3399ff;
  }

  .Home_card__2A8-W p{
    color: #3399ff;
  }

  .Home_flagCard__1saFd {
    margin-bottom: 5%;
    margin-top: 20% !important;
  }

  .Home_section__1qsjp {
    background: #3399ff;
  }

  .Home_mobileSpacer__lSXnD {
    height: 2px !important;
	  color:  black;
  }
}

@media only screen and (max-width: 375px){
  .Home_nav__2RJUg {
    position: relative;
  }

  .Home_smallScreen__2cJqy {
    display: block;
    margin-top: 10%;
  }
  
  .Home_background__1VzIz {
    background-image: url(/static/media/home_banner_mobile3.2b441bb1.png);
  }

  .Home_parkPics__xwm8s {
    width: 300px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Home_coords__O2QeX {
    text-align: center;
  }

  .Home_compass__1WaYp {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Home_card__2A8-W {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    background: #fff !important;
    width: 310px;
  }

  .Home_image__2g7nc {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Home_card__2A8-W h3 {
    color: #3399ff;
  }

  .Home_card__2A8-W p{
    color: #3399ff;
  }

  .Home_flagCard__1saFd {
    margin-bottom: 5%;
    margin-top: 20% !important;
  }
  .Home_marinaCard__2aYkr {
    margin-bottom: 5%;
    margin-top: 15%;
  }

  .Home_eventsCard__3aYAX {
    margin-bottom: 5%;
    margin-top: 10% !important;
  }

  .Home_section__1qsjp {
    background: #3399ff;
  }

  .Home_mobileSpacer__lSXnD {
    height: 2px !important;
	  color:  black;
  }
}
.Footer_container__3GNCk {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.Footer_footer__23AIx {
  background: #e6e6e6;
}

.Footer_footerList__36uSA {
  margin-right: 100px;
}

.Footer_footer__23AIx h4{
  margin-top: 5%;
  color: #08458e;
  text-align: center;
}

.Footer_footer__23AIx h2 {
  color: #08458e;
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 3rem;
  text-align: center;
}


.Footer_footer__23AIx h2:hover {
  cursor: pointer;
  text-decoration: none;
  opacity: 80%;
}

.Footer_footer__23AIx p {
  color: #08458e;
  text-align: center;
}

.Footer_footer__23AIx ul{
  list-style: none;
  margin-top: 5%;
}

.Footer_footerLogo__32Ni0 a{
  color: #06468c;
  font-size: 2.3rem;
}

.Footer_linkText__3xmG7 {
  color: #08458e;
  cursor: pointer;
}

.Footer_linkText__3xmG7:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;;
}

.Footer_copyright__3MsSL {
  background: #d9d9d9;
  height: 100px;
}

.Footer_xlLink__we8X3 {
  color: #08458e;
}

@media only screen and (max-width: 810px) {
  .Footer_footer__23AIx h2 {
    margin-top: -5px;
  }

  .Footer_footerList__36uSA {
    margin-left: 0;
  }
  
  .Footer_linkText__3xmG7:hover {
    text-decoration: underline;
  }

  .Footer_footerList__36uSA {
    margin-right: 0px;
  }

}

@media only screen and (max-width: 500px) {
  .Footer_footerList__36uSA {
    margin-right: 35px !important;
  }

}
.Login_background__2Y-rm{
	background-image: url(/static/media/marina.c3693b8e.jpg);
	height: 500px !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: multiply;
}

.Login_container__24DKt {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.Login_emailInputBox__-Wy7I {
  display: none;
}

.Login_loginHeader__2H4nI {
  color: #08458e; 
  text-align: center;
}

.Login_loginBtn__2FDhU {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media only screen and (max-width: 810px) {
  .Login_container__24DKt {
    margin: 0 auto;
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .Login_container__24DKt {
    margin: 0 auto;
    width: 80%;
  }

  .Login_background__2Y-rm {
    height: 350px;
  }
}
.Loggedin_memberTitle__3dRCQ{
  color: #08458e;
  font-family: 'Dosis', sans-serif;
  text-align: center;
  font-size: 3rem;
}

.Loggedin_breakWall__2o7YN {
  width: 100%;
  height: auto;
}

.Loggedin_breakWallCard__3J7Q7 p{
 color: #08458e;
 margin-top: 20%;
 margin-bottom: 20%;
 font-size: 1.5rem;
}

.Loggedin_breakWallCard__3J7Q7 a {
  font-size: 1.5rem;
}

.Loggedin_notice__lh7Rj {
  width: 80%;
  height: auto;
}

.Loggedin_noticeCard__1ILNN p {
  color: #08458e;
  font-size: 1.3rem;
}

.Loggedin_documentTitle__2SQXe {
  text-align: center;
  color: #08458e;
  font-size: 2.5rem;
}

.Loggedin_documentList__3OsWx {
  list-style-type: none;
  margin-top: 20px;
}

.Loggedin_documentList__3OsWx a {
  color: #08458e;
}

.Loggedin_documentList__3OsWx li {
  margin-bottom: 40px;
}
.Loggedin_documentList__3OsWx img {
  margin-right: 10px;
}

.Loggedin_pdf__1fAdP {
  width: 20px;
  height: 20px;
  float: left;
}

.Loggedin_pdfIcon__3bXVF {
  margin-right: 5px;
}

@media only screen and (max-width: 810px) {
  .Loggedin_pdfs__1t-rU {
    display: flex;
    justify-content: center;
  }

  .Loggedin_breakWallCard__3J7Q7 a {
    font-size: 1rem;
  }

  .Loggedin_breakWallCard__3J7Q7 p {
    font-size: 1.1rem;
  }

  .Loggedin_noticeCard__1ILNN p {
    font-size: 0.9rem;
  }

}

@media only screen and (max-width: 500px) {
  .Loggedin_breakWallCard__3J7Q7 {
    margin-bottom: 10%;
  }

  .Loggedin_pdfs__1t-rU {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .Loggedin_documentTitle__2SQXe {
    font-size: 1.8rem;
  }
}
.Events_nav__2ebmw {
  background: rgba(0,0,0,0.5);
}

.Events_background__2yT8W {
  background-image: url(/static/media/banner3.94a8e5e1.jpg);
  height: 900px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.Events_eventsHeader__25S6j {
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: #08458e;
  text-align: center;
}

.Events_container__38J7D {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.Events_eventSchedule__2zAPi {
  width: 550px;
  height: auto;
  margin-top: 2.5%;
  border: none;
}

.Events_eventScheduleWrapper__rbHvx {
  display: flex;
  justify-content: center;
}

.Events_divider__3oLYs {
  height: 10px;
}

@media only screen and (max-width: 1100px) {
  .Events_background__2yT8W{
    height: 600px;
  }

  .Events_eventSchedule__2zAPi {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 400px;
    height: auto;
  }

  .Events_eventsImg__38bNN {
    width: 400px;
    height: auto;
  }
}

@media only screen and (max-width: 810px) {
  .Events_upcomingEvents__3ESv0 {
    width: 100%;
    margin-top: 45%;
  }

  .Events_eventSchedule__2zAPi {
    width: 100%;
    height: auto;
  }

  .Events_container__38J7D {
    width: 80%;
  }

  .Events_background__2yT8W{
    height: 1000px;
  }

  .Events_eventsImg__38bNN {
    width: 100%;
    height: auto;
  }
  
}
/* 
@media (max-width: 420px) {
  .background {
    height: 600px;
  }
} */

@media only screen and (max-width: 500px) {
  .Events_container__38J7D {
    margin: 0 auto;
    width: 80%;
  }

  .Events_upcomingEvents__3ESv0 {
    width: 300px;
    margin-top: 60%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  .Events_eventSchedule__2zAPi {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .Events_eventsImg__38bNN {
    width: 100%;
    height: auto;
  }

  .Events_background__2yT8W {
    height: 650px;
  }

  body {
    overflow-x: hidden;
  }

  .Events_divider__3oLYs {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 375px) {
 .Events_background__2yT8W {
  height: 900px;
 }
}
.Contact_background__8GkOt {
  background-image: url(/static/media/banner3.94a8e5e1.jpg);
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.Contact_contactHeader__3GxV1 {
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: #08458e;
  text-align: center;
}

.Contact_contactCard__1Mbom {
  margin-top: 0px;
}

.Contact_contactImg__3WARU {
  border: none !important;
}

.Contact_contactBody__3ueKj {
  text-align: center;
  color: #08458e;
}

.Contact_lighthouseImg__3Y90p {
  margin-bottom: 40px;
}

.Contact_playFair__2AuKE {
  font-family: Dosis, serif;
}

/* mobile styles */
@media only screen and (max-width: 810px) {
  .Contact_contactImg__3WARU {
    width: 350px;
    margin-top: 60%;
  }

  .Contact_contactCard__1Mbom {
    margin-top: 60%;
  }

  .Contact_background__8GkOt {
    height: 700px;
  }

  .Contact_contactHeader__3GxV1 {
    margin-top: 25%;
  }

  .Contact_contactCard__1Mbom {
    margin-top: 0;
  }
  
  .Contact_contactImg__3WARU {
    margin-top: 0;
  }

}

@media only screen and (max-width: 500px){
  .Contact_contactCard__1Mbom {
    margin-top: 5%;
  }

  .Contact_contactImg__3WARU {
    margin-top: 10%;
    width: 100%;
  }

  .Contact_background__8GkOt {
    height: 1000px;
  }

  .Contact_contactHeader__3GxV1 {
    margin-top: 40%;
  }

}
.Info_container__1Xl-5 {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.Info_infoHeader__29CLC {
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: #08458e;
  text-align: center;
}

.Info_background__2wrAA {
  background-image: url(/static/media/banner5.a8e1c810.jpg);
  height: 3500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.Info_overflowCard__2foha {
  margin: 10% 0;
}

.Info_overFlowTitle__YPfjU {
  color: #08458e;
  text-align: center;
  font-size: 3rem;
  font-family: 'playfair', sans-serif;
}

.Info_conditionsCard__24dqY {
  margin: 10% 0 5% 0;
}

.Info_map__eu6_V{
  width: 540px;
  height: 800px;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Info_parkingCard__2KGof {
  margin-bottom: 10%;
}

.Info_parkingTitle__196jh {
  text-align: center;
  color: #08458e;
}

.Info_parkingText__2ESik {
  color: #08458e;
}

.Info_parkingImg__3reQd {
  width: 100%;
  height: auto;
}

.Info_weatherTitle__2U9iF {
  text-align: center;
  color: #08458e;
}

.Info_spacer__1L8NK {
  width: 90%;
  border-top: 2px solid #08458e;
}

.Info_icons__3M5Oo {
  width: 40px;
  height: 40px;
  float: left;
}

.Info_bottomWebCam__1DcFI {
  width: 40px;
  height: 40px;
  float: left;
  padding-top: 3px;
}

.Info_iconText__39xtr {
  margin-top: 4%;
  color: #08458e;
}

.Info_weatherAPITitle__BYnFx {
  text-align: center;
  color: #fff;
  margin-top: 10%;
}

@media only screen and (max-width: 810px) {
  .Info_map__eu6_V {
    margin-top: 60%;
  }

  .Info_iconText__39xtr {
    font-size: 1.1rem;
  }

  .Info_icons__3M5Oo {
    width: 30px;
    height: 30px;
  }

  .Info_bottomWebCam__1DcFI{
    width: 30px;
    height: 30px;
  }

  .Info_map__eu6_V {
    width: 100%;
    height: auto;
    margin-top: 5%;
  }

  .Info_infoHeader__29CLC {
    margin-top: 25%;
  }

  .Info_background__2wrAA {
    height: 2800px;
  }
}

@media only screen and (max-width: 500px) {
  .Info_map__eu6_V {
    width: 100%;
    height: auto;
  }

  .Info_container__1Xl-5 {
    margin: 0 auto;
    width: 80%;
  }

  .Info_parkingImg__3reQd {
    margin-top: 10%;
  }

  .Info_background__2wrAA {
    height: 3200px;
  }

  .Info_icons__3M5Oo {
    width: 30px;
    height: 30px;
  }

  .Info_iconText__39xtr {
    font-size: 1rem;
  }

  .Info_bottomWebCam__1DcFI {
    width: 30px;
    height: 30px;
  }

  .Info_infoHeader__29CLC {
    margin-top: 50%;
  }

  .Info_map__eu6_V {
    margin-top: 5%;
  }
}
.Safety_background__3wjyc {
  background-image: url(/static/media/banner3.94a8e5e1.jpg);
  height: 3450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.Safety_safetyHeader__2lkaZ {
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: #08458e;
  text-align: center;
}

.Safety_divider__1P57G {
  background-color: #fff;;
}

.Safety_safetyNav__27Lk- ul{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background:  #08458e;
  width: 1100px;
  height: 60px;
 
}

.Safety_safetyList__WHQb6 {
  list-style-type: disc;
  list-style-type: initial;
}

.Safety_subList__1mMUO {
  list-style-type: disc;
  list-style-type: initial;
}

.Safety_subList__1mMUO li {
  color: blue;
}

.Safety_safetyMenu__1raFp ul li a {
  color: #fff;
}

.Safety_safetyMobileLinks__2Bn1v li{
  display: none !important;
}

.Safety_cardHeader__1vaXe{
  color: #08458e;
  font-family: 'Dosis', sans-serif;
  font-size: 3rem;
}

.Safety_cardText__HZEfy {
  color: #08458e;
}

.Safety_safetyList__WHQb6 li {
  color: #08458e;
}

.Safety_safetyMap__Rumfq:hover { 
  cursor: zoom-in;
}

.Safety_subHeader__1CvYY {
  font-weight: bold;
}

@media only screen and (max-width: 810px) {
  .Safety_safetyHeader__2lkaZ {
    margin-top: 25%
  }

  .Safety_safetyNav__27Lk- ul{
    width: 615px;
  }

  .Safety_safetyMap__Rumfq {
    width: 100%;
  }

  .Safety_background__3wjyc {
    height: 3750px;
  }
}

@media only screen and (max-width: 700px) {
  .Safety_safetyNav__27Lk- {
    display: none;
  }

  .Safety_safetyHeader__2lkaZ {
    margin-top: 45%;
    text-align: center;
  }
  
  .Safety_safetyMobileLinks__2Bn1v li{
    display: block !important;
    text-align: center;
  }

  .Safety_background__3wjyc {
    height: 3900px;
  }

  .Safety_safetyMap__Rumfq {
    width: 100%;
    margin-top: 10%;
  }

  .Safety_cardHeader__1vaXe {
    font-size: 1rem;
  }

  .Safety_background__3wjyc {
    height: 3200px;
  }
}

@media only screen and (max-width: 500px) {
  .Safety_safetyMobileLinks__2Bn1v a {
    color: #fff;
  }

  .Safety_background__3wjyc {
    height: 3600px !important;
  }
}
:root {
  --header-color: #08458e;
}

.Forsale_centerImg__3s-xk {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Forsale_background__1SgcX {
  background-image: url(/static/media/banner4.ffcd5ef1.jpg);
	height: 1250px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: multiply;
}

.Forsale_blue__1-e2o {
  color: #08458e;
  color: var(--header-color);
  font-size: 1.3rem;
}

.Forsale_forSaleCard___KEgD {
  margin-top: 20%;
  width: 900px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.Forsale_forSaleHeader__11foj {
  margin-top: 0%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: #08458e;
  text-align: center;
}

.Forsale_forSaleHeaderSmall__2VF7n {
  color: #08458e;
  font-family: 'Dosis', sans-serif;
  font-size: 2rem;
  text-align: center;
}

.Forsale_forSaleDesc__1c9ce {
  color: #08458e;
  text-align: center;
  font-size: 1.5rem;
}

.Forsale_spacer__1QWht {
  border-top: 2px solid #08458e; 
}

.Forsale_forSaleText__2AnuB {
  color: #08458e;
  font-size: 1.5rem;
}

ul {
  color: #08458e;
  margin-top: 20px;
  list-style: none;
}

.Forsale_center__1fB2o {
  text-align: center;
}

li {
  font-size: 1.3rem;
}

.Forsale_itemImg__2loIx img {
  width: 100%;
  height: auto;
}

.Forsale_itemDescription__fdXw8 {
  border: 1px solid grey;
  padding-bottom: 10px;
  margin-top: 10px;
}

.Forsale_itemDescriptionList__ClmFM {
  list-style-type: disc;
  text-align: left;
}

.Forsale_contactBtn__KRyov {
  border: none;
  background: #0099ff;
  border-radius: 5px;
  padding: 5px;
}

.Forsale_contactBtn__KRyov a {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
}

.Forsale_contactBtn__KRyov:hover {
  opacity: 0.7;
}

.Forsale_itemPhoneNumber__3YblZ {
  font-size: 1.2rem;
}

.Forsale_no-listing-img__YN0yV {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 810px) {

  body {
    width: 100%;
    margin: auto;
  }
  
  .Forsale_forSaleImgs__1whsj {
    width: 100%;
  }

  .Forsale_background__1SgcX {
    height: 1350px
  }

  .Forsale_container__Er6e- {
    margin: 0 auto;
    width: 80%;
  }

  .Forsale_forSaleCard___KEgD {
    margin-top: 180px;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  body {
    width: 100%;
    margin: auto;
  }

  .Forsale_background__1SgcX {
    height: 1400px;
  }

  .Forsale_forSaleImgs__1whsj {
    width: 100%;
  }

  .Forsale_forSaleCard___KEgD {
    width: 100%;
  }

  .Forsale_breakWallCard__ZZ7si {
    margin-bottom: 20px;
  }

  .Forsale_map__31SKC {
    width: 100%;
    height: auto;
  }

  .Forsale_show__1QNDI {
    display: block;
  }

  .Forsale_camperGrid__2__Yl {
    grid-template-columns: 1fr;
  }

  .Forsale_dockImgs__2kktk {
    width: 100%;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }

}

@media only screen and (max-width: 501px) {
  .Breakwall_img__jyHt3 {
    margin: 5% 0;
  }
}
.PoolTable_card__3mSY7 {
  margin: 5% 0;
}
