@value text-color: #08458e;
@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');

.background {
  background-image: url('../../images/banner3.jpg');
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.contactHeader {
  margin-top: 15%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: text-color;
  text-align: center;
}

.contactCard {
  margin-top: 0px;
}

.contactImg {
  border: none !important;
}

.contactBody {
  text-align: center;
  color: text-color;
}

.lighthouseImg {
  margin-bottom: 40px;
}

.playFair {
  font-family: Dosis, serif;
}

/* mobile styles */
@media only screen and (max-width: 810px) {
  .contactImg {
    width: 350px;
    margin-top: 60%;
  }

  .contactCard {
    margin-top: 60%;
  }

  .background {
    height: 700px;
  }

  .contactHeader {
    margin-top: 25%;
  }

  .contactCard {
    margin-top: 0;
  }
  
  .contactImg {
    margin-top: 0;
  }

}

@media only screen and (max-width: 500px){
  .contactCard {
    margin-top: 5%;
  }

  .contactImg {
    margin-top: 10%;
    width: 100%;
  }

  .background {
    height: 1000px;
  }

  .contactHeader {
    margin-top: 40%;
  }

}