@value text-color: #08458e;
@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');
.nav {
  background: rgba(0,0,0,0.5);
}

.background {
  background-image: url('../../images/banner3.jpg');
  height: 900px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.eventsHeader {
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: text-color;
  text-align: center;
}

.container {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.eventSchedule {
  width: 550px;
  height: auto;
  margin-top: 2.5%;
  border: none;
}

.eventScheduleWrapper {
  display: flex;
  justify-content: center;
}

.divider {
  height: 10px;
}

@media only screen and (max-width: 1100px) {
  .background{
    height: 600px;
  }

  .eventSchedule {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 400px;
    height: auto;
  }

  .eventsImg {
    width: 400px;
    height: auto;
  }
}

@media only screen and (max-width: 810px) {
  .upcomingEvents {
    width: 100%;
    margin-top: 45%;
  }

  .eventSchedule {
    width: 100%;
    height: auto;
  }

  .container {
    width: 80%;
  }

  .background{
    height: 1000px;
  }

  .eventsImg {
    width: 100%;
    height: auto;
  }
  
}
/* 
@media (max-width: 420px) {
  .background {
    height: 600px;
  }
} */

@media only screen and (max-width: 500px) {
  .container {
    margin: 0 auto;
    width: 80%;
  }

  .upcomingEvents {
    width: 300px;
    margin-top: 60%;
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  .eventSchedule {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .eventsImg {
    width: 100%;
    height: auto;
  }

  .background {
    height: 650px;
  }

  body {
    overflow-x: hidden;
  }

  .divider {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 375px) {
 .background {
  height: 900px;
 }
}