@value text-color: #08458e;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.background {
  background-image: url('../../images/home_banner.png');
  height: 750px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 25px;
}

.smallScreen {
  display: none;
}

.image {
  width: 90%;
  height: 500px;
}

.bandImage {
  width: 100%;
  height: 300px;
}

.coords {
  color: text-color;
  margin-left: 0px;
}

.compass {
  margin-left: 150px;
}

.card {
  background: #3399ff !important;
  width: 400px;
  margin-left: 50px !important;
  margin-top: -50px;
}

.card h3 {
  color: #fff;
  text-align: center;
}

.card p {
  color: #fff;
}

.flagCard {
  margin-top: 20%;
}

.marinaCard {
  margin-top: 20%;
}

.eventsCard {
  margin-top: 10%;
}

.button {
  width: 200px;
  height: 40px;
  padding: 7px;
  color: #fff;
  background: gray;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.button:hover {
  text-decoration: none;
}

.parkPicsTitle {
  font-family: Playfair Display, serif;
  text-align: center;
  margin-top: 10%;
  color: text-color;
}

.parkPics {
  width: 350px;
  height: 350px;
}

.compassContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Mobile styles */
@media only screen and (min-width: 800px) and (max-width: 990px) {
  .background {
    background-image: url('../../images/home_banner_medium.png');
  }

  .smallScreen {
    display: block;
  }

  .largeScreen {
    display: none;
  }

  .coords {
    text-align: center;
  }

  .compass {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .parkPics {
    width: 300px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .card {
    margin-left: 120px !important;
    margin-top: -50px !important;
    width: 500px;
    background: #fff !important;
  }

  .card h3 {
    color: #3399ff;
  }

  .card p{
    color: #3399ff;
  }

  .flagCard {
    margin-bottom: 5%;
    margin-top: 10% !important;
  }
  .marinaCard {
    margin-bottom: 5%;
    margin-top: 10%;
  }

  .eventsCard {
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .section {
    background: #3399ff;
  }

  .mobileSpacer {
    height: 2px !important;
	  color:  black;
  }

  .image {
    margin-left: 120px;
    margin-bottom: 5%;
    width: 500px;
  }
}

@media only screen and (max-width: 500px) {  
  .background {
    background-image: url('../../images/home_banner_mobile3.png');
  }

  .coords {
    text-align: center;
  }

  .flagCard {
    margin-bottom: 5%;
    margin-top: 20% !important;
  }
  .marinaCard {
    margin-bottom: 5%;
    margin-top: 15%;
  }

  .eventsCard {
    margin-bottom: 5%;
    margin-top: 10% !important;
  }

  .nav {
    position: relative;
  }

  .smallScreen {
    display: block;
    margin-top: 10%;
  }

  .largeScreen {
    display: none;
  }

  .parkPics {
    width: 300px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .coords {
    text-align: center;
  }

  .compass {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 150px;
    height: auto;
  }

  .card {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    background: #fff !important;
    width: 350px;
  }

  .image {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    height: auto;
  }

  .golfCartImage {
    width: 100%;
  }

  .overviewImage {
    width: 100%;
    height: 300px;
  }

  .card h3 {
    color: #3399ff;
  }

  .card p{
    color: #3399ff;
  }

  .flagCard {
    margin-bottom: 5%;
    margin-top: 20% !important;
  }

  .section {
    background: #3399ff;
  }

  .mobileSpacer {
    height: 2px !important;
	  color:  black;
  }
}

@media only screen and (max-width: 375px){
  .nav {
    position: relative;
  }

  .smallScreen {
    display: block;
    margin-top: 10%;
  }
  
  .background {
    background-image: url('../../images/home_banner_mobile3.png');
  }

  .parkPics {
    width: 300px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .coords {
    text-align: center;
  }

  .compass {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .card {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    background: #fff !important;
    width: 310px;
  }

  .image {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .card h3 {
    color: #3399ff;
  }

  .card p{
    color: #3399ff;
  }

  .flagCard {
    margin-bottom: 5%;
    margin-top: 20% !important;
  }
  .marinaCard {
    margin-bottom: 5%;
    margin-top: 15%;
  }

  .eventsCard {
    margin-bottom: 5%;
    margin-top: 10% !important;
  }

  .section {
    background: #3399ff;
  }

  .mobileSpacer {
    height: 2px !important;
	  color:  black;
  }
}