@value text-color: #08458e;

.background{
	background-image: url('../../images/marina.jpg');
	height: 500px !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: multiply;
}

.container {
  max-width: 1100px;
  width: 100%;
  height: auto;
  margin: auto;
}

.emailInputBox {
  display: none;
}

.loginHeader {
  color: text-color; 
  text-align: center;
}

.loginBtn {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media only screen and (max-width: 810px) {
  .container {
    margin: 0 auto;
    width: 80%;
  }
}

@media only screen and (max-width: 500px) {
  .container {
    margin: 0 auto;
    width: 80%;
  }

  .background {
    height: 350px;
  }
}