@value text-color: #08458e;
@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');

:root {
  --header-color: #08458e;
}

.centerImg {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.background {
  background-image: url('../../images/banner4.jpg');
	height: 1250px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: multiply;
}

.blue {
  color: var(--header-color);
  font-size: 1.3rem;
}

.forSaleCard {
  margin-top: 20%;
  width: 900px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.forSaleHeader {
  margin-top: 0%;
  font-family: 'Dosis', sans-serif;
  font-size: 4rem;
  color: text-color;
  text-align: center;
}

.forSaleHeaderSmall {
  color: text-color;
  font-family: 'Dosis', sans-serif;
  font-size: 2rem;
  text-align: center;
}

.forSaleDesc {
  color: text-color;
  text-align: center;
  font-size: 1.5rem;
}

.spacer {
  border-top: 2px solid #08458e; 
}

.forSaleText {
  color: text-color;
  font-size: 1.5rem;
}

ul {
  color: text-color;
  margin-top: 20px;
  list-style: none;
}

.center {
  text-align: center;
}

li {
  font-size: 1.3rem;
}

.itemImg img {
  width: 100%;
  height: auto;
}

.itemDescription {
  border: 1px solid grey;
  padding-bottom: 10px;
  margin-top: 10px;
}

.itemDescriptionList {
  list-style-type: disc;
  text-align: left;
}

.contactBtn {
  border: none;
  background: #0099ff;
  border-radius: 5px;
  padding: 5px;
}

.contactBtn a {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
}

.contactBtn:hover {
  opacity: 0.7;
}

.itemPhoneNumber {
  font-size: 1.2rem;
}

.no-listing-img {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 810px) {

  body {
    width: 100%;
    margin: auto;
  }
  
  .forSaleImgs {
    width: 100%;
  }

  .background {
    height: 1350px
  }

  .container {
    margin: 0 auto;
    width: 80%;
  }

  .forSaleCard {
    margin-top: 180px;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  body {
    width: 100%;
    margin: auto;
  }

  .background {
    height: 1400px;
  }

  .forSaleImgs {
    width: 100%;
  }

  .forSaleCard {
    width: 100%;
  }

  .breakWallCard {
    margin-bottom: 20px;
  }

  .map {
    width: 100%;
    height: auto;
  }

  .show {
    display: block;
  }

  .camperGrid {
    grid-template-columns: 1fr;
  }

  .dockImgs {
    width: 100%;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }

}
