@value text-color: #08458e;
@import url('https://fonts.googleapis.com/css?family=Dosis:800&display=swap');

.memberTitle{
  color: text-color;
  font-family: 'Dosis', sans-serif;
  text-align: center;
  font-size: 3rem;
}

.breakWall {
  width: 100%;
  height: auto;
}

.breakWallCard p{
 color: text-color;
 margin-top: 20%;
 margin-bottom: 20%;
 font-size: 1.5rem;
}

.breakWallCard a {
  font-size: 1.5rem;
}

.notice {
  width: 80%;
  height: auto;
}

.noticeCard p {
  color: text-color;
  font-size: 1.3rem;
}

.documentTitle {
  text-align: center;
  color: text-color;
  font-size: 2.5rem;
}

.documentList {
  list-style-type: none;
  margin-top: 20px;
}

.documentList a {
  color: text-color;
}

.documentList li {
  margin-bottom: 40px;
}
.documentList img {
  margin-right: 10px;
}

.pdf {
  width: 20px;
  height: 20px;
  float: left;
}

.pdfIcon {
  margin-right: 5px;
}

@media only screen and (max-width: 810px) {
  .pdfs {
    display: flex;
    justify-content: center;
  }

  .breakWallCard a {
    font-size: 1rem;
  }

  .breakWallCard p {
    font-size: 1.1rem;
  }

  .noticeCard p {
    font-size: 0.9rem;
  }

}

@media only screen and (max-width: 500px) {
  .breakWallCard {
    margin-bottom: 10%;
  }

  .pdfs {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .documentTitle {
    font-size: 1.8rem;
  }
}